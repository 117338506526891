.plan-selection {

	.plan {
		@extend .row;
		@extend .no-gutters;
		@include mt(4);
		@include media-breakpoint-up(md) { @include mt(3); height: 4rem; }
		border: 1px solid $dark;
	}

	header {
		@extend .col-md;
		@include p(0);
		@include pl(2);
		@include pt(2);

		@include media-breakpoint-down(sm) {
			@include p(1);
			background: $dark;
			color: white;
			text-align: center;
			h4, .speeds { display: inline; }
			h4 { @include mr(2); }
		}

		h4 {
			@include mb(1);
			font-size: 1.5em;
			vertical-align: middle;
		}

		.speeds { font-size: 0.8em; }
		.speed, i { @include mr(1); }
	}

	.price {
		@extend .col-md;
		@include p(0);

		@include media-breakpoint-down(sm) {
			border-top: 1px solid $dark;
			text-align: center;
			.dollars, .term { display: inline; }
		}

		.dollars {
			@include mt(1);
			font-size: 1.5em;
			vertical-align: middle;
		}

		.term {
			@extend .text-muted;
			font-size: 0.7em;
		}
	}

	.offer {
		@extend .col-md-auto;
		@extend .btn;
		@extend .btn-outline-dark;
		@include p(1);
		height: 100%;
		text-align: center;

		@include media-breakpoint-up(md) {
			border-bottom: none;
			border-top: none;
			display: flex;
			flex-direction: column;
			flex-grow: 0;
			justify-content: space-between;
			width: 4em;
		}

		@include media-breakpoint-down(sm) {
			border-left: none;
			border-right: none;
		}

		&:not([href]) {
			@include media-breakpoint-down(sm) {
				display: none;
				+ .fee, + .select-box { border-top: 1px solid $dark; }
			}
			@include media-breakpoint-up(md) {
				border-left: none;
				pointer-events: none;
				* { visibility: hidden; }
			}
		}

		i {
			font-size: 1.5em;
			vertical-align: middle;
		}

		span {
			font-size: 0.7em;
			height: 2em;
			line-height: 1;
			white-space: normal;
		}
	}

	.fee {
		@extend .col-md-1;
		@include p(1);
		background: $info;
		color: inherit !important;
		cursor: pointer;
		height: 100%;
		text-align: center;
		text-decoration: none !important;

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}

		.cost {
			font-size: 1.2em;
			vertical-align: middle;
		}

		.text {
			font-size: 0.7em;
			line-height: 1;
			white-space: normal;
			vertical-align: baseline;
		}
	}

	.select-box {
		@extend .col-md-3;
		@include p(0);
	}

	.fee + .select-box {
		@extend .col-md-2;
		@include p(0);

		@include media-breakpoint-up(md) {
			.select-plan {
				text-align: left;
				padding-left: 1.3em;
			}
		}
	}

	.select-plan {
		height: 100%;
		width: 100%;
		white-space: normal;

		@include media-breakpoint-down(lg) {
			&.cancellable .date { display: none; }
		}

		@include media-breakpoint-down(md) {
			.date { display: none; }
		}

		&.contact-us {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.new-plan { font-size: 0.8em; }
}
