#dev-breakpoint-box {
	background: #eee;
	border: 2px solid #FF1493;
	cursor: move;
	font-weight: bold;
	left: 20vw;
	padding: 10px 20px;
	position: absolute;
	top: 6rem;
	z-index: 1295827;
}
