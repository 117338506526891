$custom-colours: (
	'darkcyan': $darkcyan,
	'offwhite': $offwhite,
);

@each $name, $value in $custom-colours {
	:root { --#{$name}: #{$value}; }
	.bg-#{$name} { background: var(--#{$name}) !important; }
	.text-#{$name} { color: var(--#{$name}) !important; }
}

@each $name, $value in $colors {
	.bg-#{$name} { background: var(--#{$name}) !important; }
	.text-#{$name} { color: var(--#{$name}) !important; }
}
