@import 'menu';

@mixin footer-when-thin {
	.text-right { text-align: unset !important; }
}

body {
	background-image: url(../images/background.jpg);
	background-color: $primary;
	background-size: cover;
	background-attachment: fixed;

	min-width: map-get($container-max-widths, 3xs);
	min-height: 100vh;
	z-index: 0;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr var(--max-width-now) 1fr;

	@include media-breakpoint-down(xs) {
		grid-template-columns: 0 1fr 0;
	}

	grid-template-rows:
		[top] fit-content(calc(var(--nav-height) * 2))
		[nav] var(--nav-height)
		[content] 1fr
		[footer] calc(var(--foot-border) + var(--foot-height))
		[end];

	grid-template-areas:
		"banner banner banner"
		"nav     nav      nav"
		".      content     ."
		"footer footer footer";

	&.thin {
		--foot-height: #{bsize(3) * 4 + 2rem};

		@include media-breakpoint-up(sm) {
			--max-width-now: #{map-get($container-max-widths, 'sm')};
			> footer {
				@include footer-when-thin();
				.col-md { flex-basis: unset; }
			}
		}

		.nav-username { display: none; }
	}

	&.narrow {
		@include media-breakpoint-up(lg) {
			--max-width-now: #{map-get($container-max-widths, 'lg')};
		}
	}
}

:root {
	--foot-border: 0.3rem;
	--nav-height: 3.85rem;
	--foot-height: #{bsize(3) * 4};

	@include media-breakpoint-down(md) {
		--nav-height: 3rem;
		--foot-height: #{bsize(3) * 4 + 2rem};
	}

	--foot-border-segment: 25%;
	@include media-breakpoint-up(sm) {
		--foot-border-segment: 27%;
	}
}

#nav-wrap {
	grid-area: nav;
}

.has-banner {
	#banner {
		grid-area: banner;
	}

	#wrap {
		min-height: calc(100vh - var(--nav-height) * 2 - var(--foot-border) - var(--foot-height));
	}
}

#wrap {
	min-height: calc(100vh - var(--nav-height) - var(--foot-border) - var(--foot-height));
	grid-area: content;

	> .container {
		// No X padding at all at tiny width
		@include media-breakpoint-down(2xs) {
			@include px(0);
			max-width: none;
			width: 100%;
		}

		> .row {
			@include py(4);
			height: 100%;

			// Reduced X padding at small width
			@include media-breakpoint-down(xs) {
				@include px(0);
			}

			> .col {
				@include py(3);
			}
		}
	}
}

.thin > footer .container {
	@include media-breakpoint-up(sm) {
		max-width: var(--max-width-now);
	}
}

body > footer {
	@include p(4);
	@include pt(1.2rem);
	background: rgba(black, 0.9);
	font-family: var(--font-family-dosis);
	grid-area: footer;

	border: 0;
	border-top: var(--foot-border) solid $green;
	border-image: linear-gradient(90deg,
		$pink 0%,
		$pink var(--foot-border-segment),
		$orange var(--foot-border-segment),
		$orange 50%,
		$green 50%,
		$green calc(100% - var(--foot-border-segment)),
		mix($blue, white, 70%) calc(100% - var(--foot-border-segment)),
		mix($blue, white, 70%) 100%
	) 1;

	color: hsl(0, 0%, 70%);
	a, a:focus, a:hover { color: white; }
	a, span { &:not(:first-child) { @include ml(1); } @include mr(1); }
	i.sep::before { content: '/'; font-style: normal; }
	i.bsep::before { content: '\\'; font-style: normal; }
	.bold { font-weight: bold; }

	.container {
		@include media-breakpoint-down(2xs) {
			max-width: 100%;
		}
	}

	@include media-breakpoint-down(xs) {
		@include px(0);
		font-size: 0.9rem;
	}

	@include media-breakpoint-down(sm) {
		@include footer-when-thin();
	}
}
