.hidden { display: none; }

.hidden-3xs { @include media-breakpoint-only(3xs) { display: none; } }
.hidden-2xs { @include media-breakpoint-only(2xs) { display: none; } }
.hidden-xs { @include media-breakpoint-only(xs) { display: none; } }
.hidden-sm { @include media-breakpoint-only(sm) { display: none; } }
.hidden-md { @include media-breakpoint-only(md) { display: none; } }
.hidden-lg { @include media-breakpoint-up(lg) { display: none; } }
.hidden-xl { @include media-breakpoint-up(xl) { display: none; } }

.hidden-to4xs { @include media-breakpoint-down(4xs) { display: none; } }
.hidden-to3xs { @include media-breakpoint-down(3xs) { display: none; } }
.hidden-to2xs { @include media-breakpoint-down(2xs) { display: none; } }
.hidden-toxs { @include media-breakpoint-down(xs) { display: none; } }
.hidden-tosm { @include media-breakpoint-down(sm) { display: none; } }
.hidden-tomd { @include media-breakpoint-down(md) { display: none; } }
.hidden-tolg { @include media-breakpoint-down(lg) { display: none; } }
.hidden-toxl { @include media-breakpoint-down(xl) { display: none; } }

.hidden-up3xs { @include media-breakpoint-up(3xs) { display: none; } }
.hidden-up2xs { @include media-breakpoint-up(2xs) { display: none; } }
.hidden-upxs { @include media-breakpoint-up(xs) { display: none; } }
.hidden-upsm { @include media-breakpoint-up(sm) { display: none; } }
.hidden-upmd { @include media-breakpoint-up(md) { display: none; } }
.hidden-uplg { @include media-breakpoint-up(lg) { display: none; } }
.hidden-upxl { @include media-breakpoint-up(xl) { display: none; } }

.d-tosm-inline { @include media-breakpoint-down(sm) { display: inline; } }
.d-tomd-inline { @include media-breakpoint-down(md) { display: inline; } }
.d-tolg-inline { @include media-breakpoint-down(lg) { display: inline; } }

.col-invert-md {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		flex-direction: column-reverse;
		:last-child { margin-top: 0 !important; }
	}
}

@each $bp, $value in $grid-breakpoints {
	@include media-breakpoint-only($bp) {
		:root { --breakpoint-now: #{$value}; }
	}
}

:root { --max-width-now: 100%; }
@each $bp, $value in $container-max-widths {
	@include media-breakpoint-only($bp) {
		:root { --max-width-now: #{$value}; }
	}
}
