.phone-plan {
	@extend .row;
	@extend .no-gutters;
	@extend .card;
	@include mb(3);
	@include px(0.1rem);
	@include py(3);
	align-items: center;
	flex-direction: row;

	.name {
		@extend .col-sm;

		h4 {
			font-size: 1.3em;
			font-weight: bold;
			@include mb(0);
			@include mt(1);
		}

		.subtitle {
			@include mb(0);
			font-size: 0.8em;
		}
	}

	.price {
		@extend .col-sm-auto;
		@include media-breakpoint-up(md) {
			@include mr(5);
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		.dollar {
			font-size: 2em;
			@include media-breakpoint-up(md) {
				display: block;
				line-height: 1;
			}
		}

		.term {
			color: $text-muted;
			font-size: 0.8em;
			@include media-breakpoint-up(md) {
				display: block;
				line-height: 1;
				text-align: right;
			}
		}
	}

	.action {
		@extend .col-sm-3;
	}

	.btn {
		@extend .btn-lg;
		width: 100%;

		@include media-breakpoint-up(sm) {
			padding: 1rem 0.1rem;
			white-space: normal;
			word-wrap: normal;
		}
	}

	.cancellable {
		font-size: 0.8em;
		.cancel-plan { font-weight: bold; }
		@include media-breakpoint-only(sm) { @include p(0.1rem); }
	}
}
