#cardsaveform {
	// @extend .row;
	@include my(4);

	.tokens {
		// @extend .col-md;
		@include mb(3);
		display: flex;
		justify-content: space-between;
		// @include ml(auto);

		white-space: nowrap;
		text-align: center;
		// @include media-breakpoint-up(lg) {
		// 	text-align: right;
		// }
	}

	.action {
		// @extend .col-md-3;
		// @extend .col-lg-2;

		// @include media-breakpoint-down(sm) {
		// 	@include mt(4);
		// }

		// @include media-breakpoint-up(xl) {
		// 	@include mr(percentage(3 / $grid-columns));
		// }
	}

	.token {
		border-radius: 0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.03);

		height: 1.5em;
		// margin: 0 0.6rem;
		font-size: calc(80vw / 10);

		:not(.conf) {
			padding-top: 0.25em;
		}

		@include media-breakpoint-up(sm) {
			font-size: 2.8rem;
		}

		text-align: center;
		text-transform: uppercase;
		width: 1.5em;

		&.conf {
			background-color: #f7f7f7;
		}

	}



	label {
		font-size: 1rem;
		font-variant: small-caps;
		letter-spacing: 0.05em;
		line-height: 2rem;
		margin-right: 2.4rem;
		text-transform: lowercase;
		vertical-align: top;
	}

	button {
		border-radius: 0;
		height: 100%;
		min-height: 3rem;
		vertical-align: top;
	}
}
