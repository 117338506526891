.tog-label {
	@include ml(auto);
	padding: 0.4rem bsize(2) bsize(1) !important;

	&.tog-on { color: var(--pink); }
}

.tog-switch {
	@extend .btn;
	@extend .btn-sm;
	@extend .btn-action-outline-pink;
	padding: bsize(1) bsize(2) !important;

	@media (max-width: 380px) {
		@include ml(auto);
	}

	&.tog-on {
		@extend .btn-action-outline-black;
	}
}
