@mixin pseudo-arrow-base {
	content: '';
	position: absolute;
}

@mixin pseudo-arrow-x($height, $color) {
	@include pseudo-arrow-base;
	border-bottom: calc(#{$height} / 2) solid $color;
	border-top: calc(#{$height} / 2) solid $color;
	height: 100%;
	top: 0;
	width: 0;
}

@mixin pseudo-arrow-y($height, $color) {
	@include pseudo-arrow-base;
	border-left: calc(#{$height} / 2) solid $color;
	border-right: calc(#{$height} / 2) solid $color;
	left: 0;
	width: 100%;
	height: 0;
}

@mixin arrow-right($height, $color) {
	position: relative;

	&::after {
		@include pseudo-arrow-x($height, $color);
		border-left: calc(#{$height} / 2) solid transparent;
		right: 0;
	}
}

@mixin arrow-left($height, $color) {
	position: relative;

	&::before {
		@include pseudo-arrow-x($height, $color);
		border-right: calc(#{$height} / 2) solid transparent;
		left: 0;
	}
}

@mixin arrow-up($height, $color) {
	position: relative;

	&::before {
		@include pseudo-arrow-y($height, $color);
		border-bottom: calc(#{$height} / 2) solid transparent;
		top: 0;
	}
}

@mixin arrow-down($height, $color) {
	position: relative;

	&::after {
		@include pseudo-arrow-y($height, $color);
		border-top: calc(#{$height} / 2) solid transparent;
		bottom: 0;
	}
}

@function svg-arrow-right($hex-color) {
	@return 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 80"%3E%3Cpath d="M0 0l40 40L0 80V0z" fill="%23#{$hex-color}"/%3E%3C/svg%3E';
}
