.flush-cells {
	@include p(0);
	display: flex;
	flex-direction: row;

	.cell, .cell-auto {
		@include px(1.25rem);
		@include py(0.75rem);
		border-right: 1px solid $list-group-border-color;

		&:last-child { border-right: none; }
	}

	.cell { flex: 1; }
	.strong.cell { flex: 2; }
}
