.rounded {
	border-radius: $border-rounded-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-rounded-radius !important;
  border-top-right-radius: $border-rounded-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-rounded-radius !important;
  border-bottom-right-radius: $border-rounded-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-rounded-radius !important;
  border-bottom-left-radius: $border-rounded-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-rounded-radius !important;
  border-bottom-left-radius: $border-rounded-radius !important;
}
