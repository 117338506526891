.card {
	background: $offwhite;
	border: none;

	> .card-body:first-child { overflow: hidden; }
	// ^ bug? where contents can get huge transparent vertical padding
	//   somehow that prevents mouse input through to an element above
}

h6.card-subtitle {
	@include mb(2);
	color: $pink;
	font-weight: 600;
}

.card-alert {
	@include mb(4);
	@include p(0);

	.card-body {
		background-size: $progress-height $progress-height;
		border-bottom: 0.35rem solid;
	}

	.list-group li :last-child {
		@include mb(0);
	}
}

.card-alert-danger .card-body {
	@include gradient-striped();
	border-bottom-color: $red;
}
.card-alert-warning .card-body {
	@include gradient-striped();
	border-bottom-color: $orange;
}
.card-alert-info .card-body {
	@include gradient-striped($angle: 90deg);
	border-bottom-color: $blue;
	.card-title { @include mb(0); }
}
.card-alert-success .card-body {
	@include gradient-striped($angle: 90deg);
	border-bottom-color: $green;
	.card-title { @include mb(0); }
}
.card-alert-default .card-body {
	@include gradient-striped($angle: 90deg);
	border-bottom-color: $pink;
	.card-title { @include mb(0); }
}
