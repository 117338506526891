:root {
	--nav-sticky-offset: 0px;
	@include media-breakpoint-down(xs) {
		--nav-sticky-offset: var(--nav-height);
	}
}

.has-banner {
	--nav-sticky-offset: var(--nav-height);
	@include media-breakpoint-down(xs) {
		--nav-sticky-offset: calc(var(--nav-height) * 2);
	}
}

#nav-wrap {
	border: 0;
	border-bottom: 1px solid rgba(white, 0.2);
	height: var(--nav-height);

	@include media-breakpoint-down(xs) {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		background: var(--blue);
		z-index: 1000;
	}
}

.thin #primary-menu {
	max-width: var(--max-width-now);
}

#primary-menu {
	@extend .container;
	@extend .navbar;
	@extend .navbar-dark;
	@include py(0);
	@include px(0.9rem);

	align-items: stretch;

	@include media-breakpoint-down(2xs) {
		@include px(15px);
		max-width: 100%;
	}

	@include media-breakpoint-up(sm) {
		font-size: 0.8rem;
		.nav-item { padding: 0 0.75em; }
	}

	@include media-breakpoint-up(md) {
		font-size: 0.9rem;
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.1rem;
	}

	.navbar-brand {
		@extend .text-dosis;

		@include mx(0);
		@include py(0);
		@include pr(calc(var(--nav-height) / 3));
		font-size: 1em;
		height: 3.85rem;
		height: var(--nav-height);
		line-height: 3.85rem;
		line-height: var(--nav-height);
		text-transform: uppercase;

		img {
			height: 3.85rem;
			height: var(--nav-height);
			width: auto;
			// min-width: 9.25rem;
			filter: drop-shadow(0 0 0.1em rgba(0, 0, 0, 0.5));
		}
	}

	#primary-menu-collapsible {
		align-items: stretch;
		z-index: 1;

		@include media-breakpoint-up(sm) {
			height: 3.85rem;
			height: var(--nav-height);
		}

		@include media-breakpoint-down(xs) {
			background: var(--blue);
			border: 0.3rem solid var(--blue);
			border-top: 0;

			.nav-item { border-bottom: 1px solid rgba(white, 0.2); }
		}
	}

	.navbar-toggler {
		border: none;
		height: 2.5em;
		padding-right: 0;
		position: absolute;
		right: 0.75rem;
	}

	.nav-item, .navbar-text {
		@extend .text-dosis;

		@include p(0);
		@include ml(1px);
		color: white;
		font-size: 1em;
		font-weight: 400;
		line-height: 2.57rem;
		line-height: calc(var(--nav-height) / 1.5);

		> .nav-link {
			@include px(1.28rem);
			@include px(calc(var(--nav-height) / 3));
			color: inherit !important;
		}
	}

	.nav-item {
		text-transform: uppercase;

		&.active, &:hover, &:focus {
			background: white;
			color: $primary;
		}
	}

	@include media-breakpoint-up(sm) {
		.nav-logout { background: $orange; &.active, &:hover, &:focus { color: $pink; } }
		.nav-login { background: $orange; }
	}

	.nav-spacer { visibility: hidden; }
	.nav-username {
		@include media-breakpoint-down(sm) { display: none; }
		@include media-breakpoint-only(lg) { display: none; }

		color: rgba(255, 255, 255, 0.8);
		max-width: calc(var(--breakpoint-now) / 4);
		text-align: right;

		b {
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
