@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 100;
	src: /* local('Dosis Extra Light'), local('Dosis-ExtraLight'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-extra-light.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-extra-light.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 200;
	src: /* local('Dosis Light'), local('Dosis-Light'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-light.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-light.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 400;
	src: /* local('Dosis Regular'), local('Dosis-Regular'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-regular.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-regular.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 500;
	src: /* local('Dosis Medium'), local('Dosis-Medium'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-medium.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-medium.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 600;
	src: /* local('Dosis Semi-Bold'), local('Dosis-SemiBold'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-semi-bold.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-semi-bold.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 700;
	src: /* local('Dosis Bold'), local('Dosis-Bold'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-bold.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-bold.woff) format('woff');
}

@font-face {
	font-family: 'Dosis';
	font-style: normal;
	font-weight: 900;
	src: /* local('Dosis Extra Bold'), local('Dosis-ExtraBold'), local('Dosis'), */
		url(/client/fonts/dosis/dosis-extra-bold.woff2) format('woff2'),
		url(/client/fonts/dosis/dosis-extra-bold.woff) format('woff');
}
