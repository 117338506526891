.new-card iframe, iframe.add-card {
	@include mt(-2);
	@include mb(2);
	@include py(3);

	border: none;
	min-height: 450px;
	width: 100%;

	@include media-breakpoint-up(md) {
		@include pt(2.4rem);
	}

	@include media-breakpoint-down(xs) {
		@include px(2);
		min-height: 430px;
	}
}
