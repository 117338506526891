$whites: 'black' 'blue' 'indigo' 'orange' 'purple' 'pink' 'red' 'gray';
$blacks: 'white' 'yellow' 'green' 'teal' 'cyan';

@mixin action-button($text, $colour) {
	.btn-action-#{$colour} {
		@extend .btn-primary;
		align-self: flex-start;
		background-color: map-get-or-key($colors, $colour);
		border: none;
		color: $text !important;
		cursor: pointer;
		font-family: var(--font-family-dosis);
		font-weight: 700;
		position: relative;
		text-transform: uppercase;
		top: 1px;

		&:active, &:focus, &:hover {
			@if $colour == 'black' {
				background-color: #555;
			} @else if $colour == 'white' {
				background-color: #ccc;
			} @else {
				background-color: darken(map-get($colors, $colour), 10%);
			}
		}

		&.disabled, &:disabled {
			background-color: map-get-or-key($colors, $colour);
			color: $text !important;
			opacity: 0.8;
		}

		&.btn-lg {
			font-weight: 500;
		}
	}
}

@mixin action-outline-button($text, $colour) {
	.btn-action-outline-#{$colour} {
		@extend .btn-outline-primary;
		align-self: flex-start;
		background-color: none;
		border: 1px solid map-get-or-key($colors, $colour);
		color: map-get-or-key($colors, $colour) !important;
		cursor: pointer;
		font-family: var(--font-family-dosis);
		font-weight: 700;
		position: relative;
		text-transform: uppercase;
		top: 1px;

		&:active, &:focus, &:hover {
			background-color: map-get-or-key($colors, $colour);
			border-color: map-get-or-key($colors, $colour);
			color: $text !important;
		}

		&.disabled, &:disabled {
			background-color: map-get-or-key($colors, $colour);
			color: $text !important;
			opacity: 0.8;
		}
	}
}

@mixin coloured-button($text, $colour) {
	.btn-#{$colour} {
		@extend .btn-outline-dark;
		background-color: map-get-or-key($colors, $colour);
		color: $text;

		&.disabled, &:disabled {
			background-color: map-get-or-key($colors, $colour);
			color: $text !important;
		}
	}

	.btn-outline-#{$colour} {
		@extend .btn-outline-dark;
		border-color: map-get-or-key($colors, $colour);
		color: $text;

		&:hover, &:focus, &:active,
		&.disabled, &:disabled {
			border-color: map-get-or-key($colors, $colour);
			background-color: map-get-or-key($colors, $colour);
			color: $text !important;
		}
	}
}

@each $colour in $whites {
	@include action-button(white, $colour);
	@include action-outline-button(white, $colour);
	@include coloured-button(white, $colour);
}

@each $colour in $blacks {
	@include action-button(black, $colour);
	@include action-outline-button(black, $colour);
	@include coloured-button(black, $colour);
}
