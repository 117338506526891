@font-face {
	font-family: 'Bryant';
	font-style: normal;
	font-weight: 100;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-light.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-light.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: normal;
	font-weight: 400;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-regular.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-regular.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: normal;
	font-weight: 500;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-medium.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-medium.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: normal;
	font-weight: 700;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-bold.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-bold.woff) format('woff');
}


@font-face {
	font-family: 'Bryant';
	font-style: italic;
	font-weight: 100;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-light-italic.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-light-italic.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: italic;
	font-weight: 400;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-regular-italic.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-regular-italic.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: italic;
	font-weight: 500;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-medium-italic.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-medium-italic.woff) format('woff');
}

@font-face {
	font-family: 'Bryant';
	font-style: italic;
	font-weight: 700;
	src: /* local('Bryant Pro'), local('Bryant 2'), local('Bryant'), */
		url(/client/fonts/bryant/bryant-bold-italic.woff2) format('woff2'),
		url(/client/fonts/bryant/bryant-bold-italic.woff) format('woff');
}
