.plan-confirm {
	.go-back {
		@extend .btn;
		@extend .btn-secondary;
		@include arrow-left(2.4em, white);
		border: none;
		line-height: 1.6;
		i { @include mx(2); }

		@include media-breakpoint-down(sm) {
			text-align: left;
			width: 100%;
		}
	}

	.access-charge {
		@extend .col-md;
		@extend .alert-info;
		@include px(2);
		border: 1px solid rgba(0, 0, 0, 0.2);

		@include media-breakpoint-up(md) {
			@include ml(4);
			line-height: 2.2;
		}

		@include media-breakpoint-down(sm) {
			@include mt(3);
			@include py(2);
		}
	}

	.summary {
		@extend .row;
		@extend .no-gutters;
		@include mt(3);

		.plan {
			@extend .col-md;
			@include py(3);
			text-align: center;

			@include media-breakpoint-up(md) {
				height: 15rem;
			}

			&.old { background: $gray-300; }
			&.new { background: $blue; color: white; }

			h4, p { @include mb(1); }

			h4 {
				font-size: 1em;
				font-weight: bold;
			}

			.subtitle {
				@include mb(3);
				font-size: 0.7em;
			}

			.prime { font-size: 1.5em; }
			.second {
				font-size: 0.9em;
				font-weight: bold;
				margin-top: -0.5em;

				&.unlimited {
					font-variant: small-caps;
					text-transform: lowercase;
				}
			}

			.price {
				@include mt(0.25em);
				@include mb(-0.25em);
				font-size: 3em;
			}

			.speed { white-space: pre; }
		}

		.arrow {
			@extend .col-md-1;
			background: $gray-300;
			text-align: center;
			i { color: $blue; }

			@include media-breakpoint-up(md) {
				@include arrow-right(15rem, $blue);
				i { display: none; }
			}

			@include media-breakpoint-down(sm) {
				@include arrow-down(2rem, $blue);
			}
		}
	}
}
