h1, .top-level-h1 {
	color: var(--blue);
	font-family: var(--font-family-dosis);
	font-size: 1.8rem;
	font-weight: normal;
	@include mt(-2);
	@include mb(3);

	@include media-breakpoint-down(md) { font-size: 1.5rem; }
	@include media-breakpoint-down(3xs) { font-size: 1.2rem; }

	a {
		text-decoration: underline;
		&.btn { text-decoration: none; }
	}

	b { @extend .b600; }
}

main.page h1 {
	text-transform: uppercase;
}

// Adjustments to the naked top level h1 so:
.content-bound > h1, .top-level-h1, .content-bound main:not(.page) > h1 {
	// - the tops of the pink header boxes align between pages
	@include mt(-0.5em);
	@include mb(1.11em);

	// - it's readable over a whitish background
	color: white;
	text-shadow: 0 0 0.1em black;
	a, a:hover, a:focus { color: white; }

	// - it stays away from the edges of the screen
	@include media-breakpoint-down(2xs) {
		@include px(15px);
	}
}

h3 {
	font-size: 1.5rem;
	margin-bottom: 1em;

	&.alert-heading {
		@include mb(2);
		border-bottom: none;

		img {
			@include mx(2);
			@include media-breakpoint-down(md) { display: none; }
		}
	}
}

h4 {
	font-size: 1.1rem;
	margin-bottom: 1em;
}

.text-center-tolg {
	@include media-breakpoint-down(lg) { text-align: center; }
}

.ws-nowrap { white-space: nowrap; }

.b400 { font-weight: 400; }
.b500 { font-weight: 500; }
.b600 { font-weight: 600; }
.b700 { font-weight: 700; }
.b800 { font-weight: 800; }

.all-caps { text-transform: uppercase; }
.small-caps, .all-small-caps { font-variant: small-caps; }
.all-small-caps { text-transform: lowercase; }
