
.fao {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	height: 1em;
	width: 1em;

	&.fa-nz { background-image: url('../images/nz.svg'); }
}
