nav.backlinks {
	@include mt(-2);
	@include mb(4);

	color: white;
	display: flex;
	justify-content: space-between;

	.backlink {
		@extend .btn;
		@extend .btn-outline-light;
		background: rgba($blue, 0.2);
		font-family: var(--font-family-dosis);
		font-weight: bold;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			background: rgba($blue, 0.3);
		}

		&::before {
			@include fa-icon;
			@include mr(1);
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
			position: relative;
		}

		&.usage::before {
			content: fa-content($fa-var-arrow-left); /*but its actually up*/
		}

		@include media-breakpoint-up(md) {
			&.dashboard {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		.backlink {
			@include mt(2);
			background: rgba($blue, 0.5);
			font-size: 0.9rem;
			order: 1;
			width: 100%;

			&.dashboard {
				order: 0;
				&::before {
					top: -0.25em;
					content: fa-content($fa-var-level-down); /*but its actually up*/
					transform:rotate(180deg);
				}
			}
		}
	}
}
