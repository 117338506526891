.table {
	tfoot tr:first-child { th, td { border-top-width: 2px; } }

	.padding-row ~ .padding-row td {
		border-top: none;
	}
}

.table-bordered {
	background: $offwhite;
	border-color: $primary;

	thead tr:first-child { th, td { border-top: none; } }
	tbody tr:last-child { th, td { border-bottom: none; } }
	tfoot tr:last-child { th, td { border-bottom: none; } }

	th, td {
		&:first-child { border-left: none; }
		&:last-child { border-right: none; }
	}
}

.table-condensed { @extend .table-sm; }
