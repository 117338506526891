$font-family-base:
	// Brand font
	Bryant,
	// Google font single-weight fallback
	"Varela Round",
	// Safari for OS X and iOS (San Francisco) fallback
	-apple-system,
	// Chrome < 56 for OS X (San Francisco) fallback
	BlinkMacSystemFont,
	// Basic web fallback
	Arial, sans-serif,
	// Emoji fonts
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-family-sans-serif: $font-family-base;

$font-family-dosis:
	// Brand font
	Dosis,
	// Fallbacks
	$font-family-sans-serif;

$heading-font-family: $font-family-dosis;

$border-rounded-radius: 0.2rem;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$blue: hsl(206, 100%, 35%);
$darkcyan: hsl(203, 84.3%, 42.5%);
$cyan: hsl(196.9, 67.6%, 58.8%);
$green: hsl(85.5, 53.6%, 51%);
$orange: hsl(32.2, 93.2%, 54.1%);
$pink: hsl(324.3, 100%, 46.1%);
$yellow: hsl(56.7, 100%, 50%);

// 7% black
$offwhite: hsl(0, 0%, 95%);

$card-bg: mix($blue, white, 10%);
$table-border-color: $blue;

$fa-font-path: "../fonts/awesome";

$grid-breakpoints: (
  4xs: 0,
  3xs: 270px,
  2xs: 340px,
  xs: 420px,
  sm: 576px,
  md: 700px,
  lg: 800px,
  xl: 1200px
) !default;

$container-max-widths: (
  3xs: 250px,
  2xs: 300px,
  xs: 400px,
  sm: 540px,
  md: 670px,
  lg: 768px,
  xl: 1140px
) !default;



@keyframes tickappear-outer {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	20% {
		transform: scale(2);
		opacity: 0.1;
	}
	100%   {
		transform: scale(1);
		opacity:1;
	}
}

@keyframes tickappear-tick {
	0% {
		transform: scale(0);
	}
	100% {
		transform: translateX(0);
		transform: scale(1);
	}
}